import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';

import { getBongoLang } from './utils';
import logger from 'common/utils/logger';

I18n.putVocabularies(translations);

const LOGGER_PREFIX = 'I18nLoader';

function I18nLoader(props) {
  const [initialized, setInitialized] = useState(false);
  const [dateLocale, setDateLocale] = useState(enLocale);
  let currentLang = getBongoLang();

  useEffect(() => {
    async function loadMessages() {
      if (i18n.locale !== currentLang) {
        try {
          let normalizedLang = currentLang;
          let normalizedDateLang = currentLang;
          // There is currently a bug where loading and activating fr_CA is breaking something in lingui
          // We can work around by storing the fr_CA bundles as fr and then using the plural rules for fr.
          // Same for pt_BR
          if (currentLang === 'fr_CA') {
            logger.debug(`${LOGGER_PREFIX}::loadMessages, Normalizing lang from fr_CA to fr for lingui`);
            normalizedLang = 'fr';
            normalizedDateLang = 'fr-CA';
          } else if (currentLang === 'pt_BR') {
            logger.debug(`${LOGGER_PREFIX}::loadMessages, Normalizing lang from pt_BR to pt for lingui`);
            normalizedLang = 'pt';
            normalizedDateLang = 'pt-BR';
          }

          logger.info(
            `${LOGGER_PREFIX}::loadMessages, Attempting to load locale message bundle for '${currentLang}' (${`locale/${currentLang}/messages`})...`
          );
          const { messages } = await import(`locale/${currentLang}/messages`);
          logger.info(`${LOGGER_PREFIX}::loadMessages, Message bundle imported (${`locale/${currentLang}/messages`})`);

          const plurals = await import('make-plural/plurals');
          logger.debug(`${LOGGER_PREFIX}::loadMessages, Plurals imported`);

          i18n.load(normalizedLang, messages);
          logger.debug(
            `${LOGGER_PREFIX}::loadMessages, Message bundle loaded (${`locale/${currentLang}/messages`}) refrenced as: ${normalizedLang}`
          );

          i18n.loadLocaleData({
            [normalizedLang]: { plurals: plurals[normalizedLang] },
          });
          logger.debug(`${LOGGER_PREFIX}::loadMessages, Plurals loaded (${normalizedLang})`);

          i18n.activate(normalizedLang);
          logger.debug(`${LOGGER_PREFIX}::loadMessages, Language activated (${normalizedLang})`);

          logger.debug(`${LOGGER_PREFIX}::loadMessages, Loading date locale info (date-fns/locale/${normalizedDateLang})`);
          // const { default: dateLocaleBundle} = await import(`date-fns/locale/nl`);
          const { default: dateLocaleBundle} = await import(`date-fns/locale/${normalizedDateLang}`);
          logger.debug(`${LOGGER_PREFIX}::loadMessages, Date locale information loaded`);
          setDateLocale(dateLocaleBundle);
        } catch (e) {
          logger.error(`${LOGGER_PREFIX}::loadMessages, error`, e);
        }
      }

      setInitialized(true);
    }

    loadMessages();
  }, [currentLang]);

  if (!initialized) {
    return false;
  }

  return (
    <I18nProvider i18n={i18n}>
      <LocalizationProvider locale={dateLocale} dateAdapter={AdapterDateFns}>
        {props.children}
      </LocalizationProvider>
    </I18nProvider>
  );
}

I18nLoader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default I18nLoader;
