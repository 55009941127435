import React, { useEffect, useState } from 'react';
import { t, Plural } from '@lingui/macro';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Button from '@mui/material/Button';

import { COURSE } from 'common/dattrs';
import CourseList from 'components/course/CourseList';
import CourseAddDialog from 'components/course/CourseAddDialog';
import CourseService from 'service/course';
import EmptyListMessage from 'components/EmptyListMessage';
import Loader from 'components/common/loader/Loader';
import MissingInstitution from 'components/institution/MissingInstitution';

import { getCourses } from 'redux/course/course.selectors';
import { getCurrentInstitution, isInstitutionLoading } from 'redux/institution/institution.selectors';
import { isSuperUser } from 'redux/user/user.selectors';
import enums from 'graphql/enums';
import ActivityListOverview from 'components/common/ActivityListOverview';

import InstitutionContentPage from './InstitutionContentPage';
import styles from './Courses.module.scss';

function checkForAdministrativeRole(courses) {
  let isAdministrator = false;
  for (let i = 0; i < courses.length; i++) {
    if (enums.roles.INSTRUCTOR === courses[i].role) {
      isAdministrator = true;
      break;
    }
  }

  return isAdministrator;
}

function Courses(props) {
  const institution = useSelector(getCurrentInstitution);
  const institutionLoading = useSelector(isInstitutionLoading);
  const courses = useSelector(getCourses);
  const _isSuperUser = useSelector(isSuperUser);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [canAdminister, setCanAdminister] = useState(false);
  const institutionId = _.get(institution, 'id');

  useEffect(() => {
    async function fetchData() {
      setLoadingCourses(true);
      await CourseService.fetchCourses(institutionId);
      setLoadingCourses(false);
    }

    if (institutionId) {
      fetchData();
    } else {
      setLoadingCourses(false);
    }
  }, [institutionId]);

  useEffect(() => {
    setCanAdminister(checkForAdministrativeRole(courses));
  }, [courses]);

  async function onCreateCourse(name) {
    try {
      setCreateDialogVisible(false);
      setLoaderVisible(true);
      // TODO we are only allowing creating for the first org that we find
      await CourseService.createCourse(name, institution.id);
      setLoaderVisible(false);
    } catch (err) {
      console.error(`Error creating course '${name}':`, err);
    }
  }

  function courseListEmpty() {
    return !loadingCourses && _.isEmpty(courses);
  }

  function getCourseComponent() {
    if (loadingCourses || institutionLoading) {
      return null;
    }

    if (!institution) {
      return <MissingInstitution />;
    }

    // This block shouldn't ever be hit. But going to leave it in just in case.
    // A non super user should always have at least one course
    if (courseListEmpty()) {
      if (_isSuperUser) {
        return (
          <>
            <EmptyListMessage
              title={t`Start by creating a course`}
              description={t`You don't currently have any courses. Once you have a course, you can enroll instructors and learners in the course, and you can create assignments for those learners to take.`}
              action={
                <Button
                  {...COURSE.create}
                  className={styles.createButton}
                  variant='contained'
                  onClick={() => {
                    setCreateDialogVisible(true);
                  }}
                >{t`Create Course`}</Button>
              }
            />
            <ActivityListOverview />
          </>
        );
      }

      return (
        <>
          <EmptyListMessage
            title={t`Couldn't find any courses`}
            description={t`Looks like you are not enrolled in any courses yet. Once you have been added to a course, you will be able to come back here and see it.`}
          />
          <ActivityListOverview />
        </>
      );
    }

    return <CourseList canAdminister={canAdminister} courses={courses} />;
  }

  function getPageActions() {
    if (loadingCourses || _.isEmpty(courses) || !canAdminister) {
      return null;
    }

    return (
      <div className={styles.pageActions}>
        <Button
          {...COURSE.create}
          variant='contained'
          onClick={() => {
            setCreateDialogVisible(true);
          }}
        >{t`Create Course`}</Button>
      </div>
    );
  }

  function getPageDescription() {
    if (_.isEmpty(courses)) {
      return null;
    }

    const courseCount = courses.length;
    return (
      <div>
        <span {...COURSE.count} className={styles.courseCount}>
          {courseCount}
        </span>
        <span>
          <Plural value={courseCount} one='course' other='courses'></Plural>
        </span>
      </div>
    );
  }

  function getBreadcrumbs() {
    if (!institution) {
      return [];
    }

    return [<span key={'institution'}>{institution.name}</span>];
  }

  return (
    <InstitutionContentPage
      title={t`Courses`}
      breadcrumbs={getBreadcrumbs()}
      description={getPageDescription()}
      actions={getPageActions()}
    >
      <Loader visible={loaderVisible || loadingCourses} />
      {getCourseComponent()}
      {createDialogVisible && (
        <CourseAddDialog onCancel={() => setCreateDialogVisible(false)} onCreate={onCreateCourse} />
      )}
    </InstitutionContentPage>
  );
}

export default Courses;
