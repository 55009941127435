import {
  ADD_COURSE,
  ADD_COURSE_MEMBER,
  DELETE_COURSE_MEMBER,
  SET_COURSES,
  SET_ACTIVE_COURSE_MEMBERS,
  SET_ACTIVE_COURSE,
  UPDATE_COURSE_SETTINGS,
  UPDATE_COURSE_MEMBER,
} from './course.types';
import _ from 'lodash';

const INITIAL_STATE = {
  activeCourse: {},
  activeCourseMembers: {},
  courses: [],
  rowsPerPage: 10,
  currentPage: 1,
  pageCount: 1,
  coursePage: [],
};

function getSortedCourses(courses) {
  return _.orderBy(courses, [(course) => course.name.toLowerCase()], ['asc']);
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_COURSE:
      return { ...state, courses: getSortedCourses([...state.courses, action.payload]) };
    case SET_COURSES:
      return { ...state, courses: getSortedCourses(action.payload) };
    case ADD_COURSE_MEMBER: {
      const { courseId } = action.payload;
      const _members = { ...state.activeCourseMembers };
      if (_members[courseId]) {
        _members[courseId] = [..._members[courseId], action.payload];
      } else {
        _members[courseId] = [action.payload];
      }
      return { ...state, activeCourseMembers: { ..._members } };
    }
    case SET_ACTIVE_COURSE_MEMBERS: {
      const _members = { ...state.activeCourseMembers };
      _members[action.id] = action.payload;
      return { ...state, activeCourseMembers: { ..._members } };
    }
    case SET_ACTIVE_COURSE:
      return { ...state, activeCourse: action.payload };
    case UPDATE_COURSE_MEMBER: {
      const { courseId, userId } = action.payload;
      const courseMembers = { ...state.activeCourseMembers };
      const members = [...courseMembers[courseId]];
      const memberIndex = _.findIndex(members, { userId });
      members[memberIndex] = { ...action.payload };
      courseMembers[courseId] = [...members];
      return { ...state, activeCourseMembers: { ...courseMembers } };
    }
    case DELETE_COURSE_MEMBER: {
      const { courseId, userId } = action.payload;
      const courseMembers = { ...state.activeCourseMembers };
      const members = [...courseMembers[courseId]];
      _.remove(members, (member) => {
        return member.userId === userId;
      });
      courseMembers[courseId] = [...members];
      return { ...state, activeCourseMembers: { ...courseMembers } };
    }
    case UPDATE_COURSE_SETTINGS: {
      const course = action.payload;
      const courses = [...state.courses];
      const courseIndex = _.findIndex(courses, { id: course.id });
      if (courseIndex !== -1) {
        courses[courseIndex] = { ...courses[courseIndex], ...course };
      }

      let activeCourse = { ...state.activeCourse };
      if (_.get(state, 'activeCourse.id') === course.id) {
        activeCourse = { ...activeCourse, ...course };
      }

      return { ...state, courses, activeCourse };
    }
    default:
      return state;
  }
};

export default reducer;
