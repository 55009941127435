import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';

import UserService from 'service/user';
import logger from 'common/utils/logger';
import { setAuthenticating, setUserData } from 'redux/user/user.actions';
import { isAuthenticating } from 'redux/user/user.selectors';

async function fetchUser(dispatch) {
  try {
    dispatch(setAuthenticating(true));
    const cognitoUser = await Auth.currentAuthenticatedUser();
    await UserService.updateUserInfo();
    const viewer = await UserService.getViewer();

    dispatch(
      setUserData({
        ...cognitoUser,
        viewer: { ...viewer?.data?.viewer },
      })
    );
  } catch (err) {
    // 'The user is not authenticated'
    return null;
  } finally {
    dispatch(setAuthenticating(false));
  }
}

function UserProvider({ children }) {
  const dispatch = useDispatch();
  const _isAuthenticating = useSelector(isAuthenticating);
  let sessionTimer = null;

  async function startUserSessionPolling() {
    clearTimeout(sessionTimer);
    sessionTimer = setTimeout(() => {
      startUserSessionCheck();
    }, 5000);
  }

  async function startUserSessionCheck() {
    try {
      await Auth.currentSession();
      startUserSessionPolling();
    } catch (e) {
      logger.warn('User session not found. Logging out.');
      dispatch(setUserData(null));
    }
  }

  React.useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          await fetchUser(dispatch);
          break;
        case 'oAuthSignOut':
        case 'signOut':
          dispatch(setUserData(null));
          break;
        case 'tokenRefresh':
          logger.info('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          logger.error('token refresh failed');
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          logger.error('Sign in failure', data); // eslint-disable-line no-console
          break;
        default:
        // console.log('Unknown auth event', event);
      }
    });
    (async () => await fetchUser(dispatch))();
  }, [dispatch]);

  React.useEffect(() => {
    startUserSessionPolling();
  }, []); // eslint-disable-line

  if (_isAuthenticating) {
    return null;
  }

  return <>{children}</>;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
