import { ADD_ACTIVITY, UPDATE_ACTIVITY, SET_ACTIVITIES } from './activity.types';

export function addActivity(payload) {
  return {
    type: ADD_ACTIVITY,
    payload,
  };
}

export function updateActivity(payload) {
  return {
    type: UPDATE_ACTIVITY,
    payload,
  };
}

export function setActivities(payload) {
  return {
    type: SET_ACTIVITIES,
    payload,
  };
}
