import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import ListItemButton from '@mui/material/ListItemButton';
import { faCirclePlus } from '@fortawesome/pro-duotone-svg-icons/faCirclePlus';

import { ACTIVITY } from 'common/dattrs';
import ActivityService from 'service/activity';
import ActivitySettingsDialog from 'components/activity/ActivitySettingsDialog';
import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import CreateActivityDialog from 'components/activity/CreateActivityDialog';
import Section from 'components/common/Section';

import ActivityListItem from './ActivityListItem';
import styles from './ActivityList.module.scss';

function ActivityList(props) {
  const { activities, canAdminister } = props;
  const { courseId } = useParams();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [bongoDialogVisible, setBongoDialogVisible] = useState(false);
  const [createActivityType, setCreateActivityType] = useState(null);

  async function fetchData() {
    if (courseId) {
      await ActivityService.fetchActivities(courseId);
    }
  }

  return (
    <Section title={t`Assignments`} className={styles.container}>
      <div className={styles.activityListContainer}>
        {canAdminister && (
          <ListItemButton
            {...ACTIVITY.list.item.create}
            aria-label={t`Create Assignment`}
            component='a'
            onClick={() => {
              setCreateDialogVisible(true);
            }}
            className={styles.createButton}
          >
            <BongoFontAwesomeIcon className={styles.addIcon} icon={faCirclePlus} size={28} />
            <span className={styles.name}>{t`Create Assignment`}</span>
          </ListItemButton>
        )}
        <div
          {...ACTIVITY.list.items.q({ count: activities.length })}
          role='list'
          aria-label={t`Assignments`}
          className={styles.activityList}
        >
          {_.map(activities, (activity) => {
            return (
              <ActivityListItem
                key={`activity_${activity.id}`}
                className={styles.listItem}
                activity={activity}
                canAdminister={canAdminister}
              />
            );
          })}
        </div>
      </div>

      {createDialogVisible && (
        <CreateActivityDialog
          onCreate={(type) => {
            setCreateDialogVisible(false);
            setCreateActivityType(type);
            setBongoDialogVisible(true);
          }}
          onHide={() => {
            setCreateDialogVisible(false);
          }}
        />
      )}

      {bongoDialogVisible && (
        <ActivitySettingsDialog
          courseId={courseId}
          type={createActivityType}
          onHide={() => {
            setBongoDialogVisible(false);
            fetchData();
          }}
        />
      )}
    </Section>
  );
}

ActivityList.propTypes = {
  activities: PropTypes.array,
  canAdminister: PropTypes.bool,
};

ActivityList.defaultProps = {
  activities: [],
  canAdminister: false,
};

export default ActivityList;
