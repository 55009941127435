/* eslint-disable */

export const getCourseUsers = /* njsscan-ignore: node_username */ `
  query GetCourseUsers($id: ID!, $limit: Int, $nextPageToken: String) {
    getCourseUsers(id: $id, limit: $limit, nextPageToken: $nextPageToken) {
      users {
        courseId
        role
        userId
        givenName
        familyName
      }
      nextPageToken
    }
  }
`;

export const getOrgUsers = /* njsscan-ignore: node_username */ `
  query GetOrgUsers($id: ID!) {
    getOrgUsers(id: $id) {
      users {
        orgId
        userId
      }
    }
  }
`;


export const getCourses = /* GraphQL */ `
  query getCourses($orgId: ID!, $limit: Int, $nextPageToken: String) {
    getCourses(orgId: $orgId, limit: $limit, nextPageToken: $nextPageToken) {
      courses {
        id
        name
        org
        role
        autoEnrollment {
          enabled
          start
          end
        }
      }
      nextPageToken
    }
  }
`;

export const getCourse = /* GraphQL */ `
  query getCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      org
      orgId
      role
      autoEnrollment {
        enabled
        start
        end
      }
    }
  }
`;

export const getActivities = /* GraphQL */ `
  query getActivities($courseId: ID!, $limit: Int, $nextPageToken: String) {
    getActivities(courseId: $courseId, limit: $limit, nextPageToken: $nextPageToken) {
      activities {
        id
        resourceLinkId
        name
        type
        courseId
        courseName
        org
      }
      nextPageToken
    }
  }
`;

export const getActivity = /* GraphQL */ `
  query getActivity($id: ID!) {
    getActivity(id: $id) {
      id
      resourceLinkId
      name
      type
      courseId
      courseName
      org
      orgId
    }
  }
`;

export const viewer = /* GraphQL */ `
  query Viewer {
    viewer {
      username
      email
      givenName
      familyName
      isSuperUser
      tocAccepted
    }
  }
`;

export const getLaunchArgs = `
  query getLaunchArgs($courseId: ID!, $resourceLinkId: ID!, $view: String!, $preferences: String, $parameters: String) {
    getLaunchArgs(courseId: $courseId, resourceLinkId: $resourceLinkId, view: $view, preferences: $preferences, parameters: $parameters) {
      url
      inputs {
        name
        value
      }
    }
  }
`;

export const getInstitutions = `
  query getOrgs {
    getOrgs {
      orgs {
        id
        name
      }
    }
  }
`;

export const getInstitutionInfo = `
  query getOrg($id: ID!) {
    getOrg(id: $id) {
      id
      name
    }
  }
`;

export const verifyJwt = `
  query verifyJwt($token: String!) {
    verifyJwt(token: $token)
  }
`;

export const downloadSCORMPackage = /* GraphQL */ `
  query getScormZip($input: ScormInput!) {
    getScormZip(input: $input)
  }
`;
