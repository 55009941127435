import _ from 'lodash';

import {
  ADD_INSTITUTION,
  SET_CURRENT_INSTITUTION,
  SET_INSTITUTIONS,
  SET_ORG_MEMBERS,
  SET_INSTITUTIONS_LOADING,
} from './institution.types';

const INITIAL_STATE = {
  institution: {},
  institutions: [],
  orgMembers: {},
  loading: true,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_INSTITUTION:
      return { ...state, institutions: [...state.institutions, action.payload] };
    case SET_INSTITUTIONS:
      return { ...state, institutions: action.payload, defaultInstitution: _.get(action, 'payload[0]', null) };
    case SET_CURRENT_INSTITUTION:
      return { ...state, currentInstitution: action.payload };
    case SET_ORG_MEMBERS: {
      const _members = { ...state.orgMembers };
      _members[action.id] = action.payload;
      return _.merge({}, state, { orgMembers: { ..._members } });
    }
    case SET_INSTITUTIONS_LOADING:
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};

export default reducer;
