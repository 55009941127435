import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import styles from './EnrollmentSection.module.scss';

function EnrollmentSection(props) {
  const { enabled, start, end, onEnabledChanged, onStartChanged, onEndChanged } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);

  const performValidation = useCallback(() => {
    let message = null;
    if (start && end && end <= start) {
      message = t`End date must be after the start date`;
    }

    setValidationMessage(message);
  }, [start, end]);

  useEffect(() => {
    setStartDate(start);
  }, [start]);

  useEffect(() => {
    setEndDate(end);
  }, [end]);

  useEffect(() => {
    performValidation();
  }, [startDate, endDate, performValidation]);

  /* TODO. There is currently a bug with the date picker. If you have a date selected, then choose the same date in a different year,
  the date picker sees that the date changed and the event fires but the picker doesn't close. If you click away it then puts the previous date in the picker
  https://youseeu.atlassian.net/browse/YSU-12103
  */

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t`Auto Enrollment`}</h3>
        <Switch
          inputProps={{ 'aria-label': t`Auto enrollment toggle` }}
          checked={enabled}
          onChange={(e) => {
            onEnabledChanged(e.target.checked);
          }}
        />
      </div>
      <div className={styles.contents}>
        <p className={styles.description}>
          {t`When Auto Enrollment is active, any user who follows a link to the course will be automatically enrolled as a learner.`}
        </p>
        <div className={styles.dateContainer}>
          <div className={styles.dateItem}>
            <DatePicker
              label={t`Start Date (Optional)`}
              disabled={!enabled}
              value={startDate}
              onChange={(newValue) => {
                if (newValue) {
                  newValue.setHours(0, 0, 1);
                }

                onStartChanged(newValue ? newValue.getTime() : null);
                performValidation();
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>

          <div className={styles.dateItem}>
            <DatePicker
              label={t`End Date (Optional)`}
              disabled={!enabled}
              value={endDate}
              onChange={(newValue) => {
                if (newValue) {
                  newValue.setHours(23, 59, 59);
                }

                onEndChanged(newValue ? newValue.getTime() : null);
                performValidation();
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </div>
        <p className={styles.validationMessage}>{enabled && validationMessage}</p>
      </div>
    </div>
  );
}

EnrollmentSection.propTypes = {
  enabled: PropTypes.bool.isRequired,
  start: PropTypes.number,
  end: PropTypes.number,
  onEnabledChanged: PropTypes.func.isRequired,
  onStartChanged: PropTypes.func.isRequired,
  onEndChanged: PropTypes.func.isRequired,
};

EnrollmentSection.defaultProps = {
  start: null,
  end: null,
};

export default EnrollmentSection;
