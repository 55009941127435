import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import Snackbar from '@mui/material/Snackbar';

import CourseService from 'service/course';
import { copyTextToClipboard } from 'common/utils/domUtils';
import LoadingButton from 'components/common/button/LoadingButton';
import styles from './CourseLinkSection.module.scss';

function CourseLinkSection(props) {
  const { courseId } = props;
  const [snacksOpen, setSnacksOpen] = useState(false);
  const [generatingLink, setGeneratingLink] = useState(false);

  async function onCopy() {
    setGeneratingLink(true);
    const result = await CourseService.generateLink({ courseId });
    copyTextToClipboard(result);
    setSnacksOpen(true);
    setGeneratingLink(false);
  }

  return (
    <div className={styles.container}>
      <h3>{t`Course Link`}</h3>
      <div className={styles.contents}>
        <p className={styles.description}>
          {t`Sharing or embedding the course link allows users to directly launch this course content.`}
        </p>
        <div className={styles.actions}>
          <LoadingButton
            className={styles.copyButton}
            disabled={generatingLink}
            loading={generatingLink}
            variant='outlined'
            onClick={onCopy}
          >{t`Copy Link`}</LoadingButton>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        open={snacksOpen}
        onClose={() => {
          setSnacksOpen(false);
        }}
        message={t`Course link copied`}
      />
    </div>
  );
}

CourseLinkSection.propTypes = {
  courseId: PropTypes.string.isRequired,
};

CourseLinkSection.defaultProps = {
  courseId: '',
};

export default CourseLinkSection;
