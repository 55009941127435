import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import cx from 'classnames';
import _ from 'lodash';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';

import Avatar from 'components/common/avatar/Avatar';
import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import CloseableDialog from 'components/common/dialog/CloseableDialog';
import logger from 'common/utils/logger';
import { getUserEmail } from 'redux/user/user.selectors';

import styles from './MemberItem.module.scss';

const ACTION_ICON_SIZE = 24;

function MemberItem(props) {
  const {
    member: { userId, givenName, familyName, role },
    updateMember,
    deleteMember,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isPersisting, setIsPersisting] = useState(false);
  const [userRole, setUserRole] = useState(role);
  const [isDirty, setIsDirty] = useState(false);
  const [confirmDeleteDialogVisible, setConfirmDeleteDialogVisible] = useState(false);
  const currentUsersEmail = useSelector(getUserEmail);
  const nameVisible = Boolean(givenName || familyName);

  useEffect(() => {
    setIsDirty(!_.isEqual(userRole, role));
  }, [userRole, role]);

  async function _updateMember() {
    if (role === userRole) {
      // nothing has changed, no need to make a backend call
      setIsEditing(false);
      return;
    }

    const memberInfo = { ...props.member, role: userRole };
    setIsPersisting(true);

    try {
      await updateMember(memberInfo);
    } catch (e) {
      logger.error('Error updating member information', memberInfo);
    }

    setIsPersisting(false);
    setIsEditing(false);
  }

  async function _deleteMember() {
    const memberInfo = { ...props.member };
    setIsPersisting(true);

    try {
      await deleteMember(memberInfo);
    } catch (e) {
      logger.error('Error deleting member information', memberInfo);
    }

    setIsPersisting(false);
    setIsEditing(false);
  }

  function getRoleLabel(value) {
    switch (value) {
      case 'learner':
        return t`Learner`;
      case 'instructor':
        return t`Instructor`;
      default:
        return null;
    }
  }

  function getRoleInfo() {
    if (isEditing) {
      return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
          <Select
            id='course-members-role-field'
            name='user-course-role'
            variant='outlined'
            disabled={isPersisting}
            value={userRole}
            inputRef={input => input && input.focus()}
            onChange={(event) => {
              setUserRole(event.target.value);
            }}
          >
            <MenuItem value={'learner'}>{t`Learner`}</MenuItem>
            <MenuItem value={'instructor'}>{t`Instructor`}</MenuItem>
          </Select>
        </FormControl>
      );
    }

    return <span>{getRoleLabel(role)}</span>;
  }

  function getRowActions() {
    if (isEditing) {
      return (
        <>
          {isPersisting ? (
            <Box className={styles.progressContainer}>
              <CircularProgress size={ACTION_ICON_SIZE} />
            </Box>
          ) : (
            <IconButton disabled={!isDirty} className={styles.saveButton} onClick={_updateMember}>
              <BongoFontAwesomeIcon icon={faCheck} size={ACTION_ICON_SIZE} />
            </IconButton>
          )}
          <IconButton
            className={styles.cancelButton}
            disabled={isPersisting}
            onClick={() => {
              setIsEditing(false);
              setUserRole(role);
            }}
          >
            <BongoFontAwesomeIcon icon={faXmark} size={ACTION_ICON_SIZE} />
          </IconButton>
        </>
      );
    }

    return (
      <>
        <IconButton
          disabled={false}
          className={styles.addEmailButton}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <BongoFontAwesomeIcon icon={faPen} size={ACTION_ICON_SIZE} />
        </IconButton>
        <IconButton
          disabled={currentUsersEmail === userId}
          className={styles.addEmailButton}
          onClick={() => {
            setConfirmDeleteDialogVisible(true);
          }}
        >
          <BongoFontAwesomeIcon icon={faTrash} size={ACTION_ICON_SIZE} />
        </IconButton>
      </>
    );
  }

  return (
    <div tabIndex={0} role='listitem' className={cx(styles.memberItem, isEditing && styles.editing)}>
      <div className={styles.detailsContainer}>
        <Avatar
          className={styles.avatar}
          key={userId}
          size={36}
          email={userId}
          firstName={givenName}
          lastName={familyName}
        />
        <div className={styles.nameContainer}>
          {nameVisible && <span>{`${givenName} ${familyName}`}</span>}
          <span className={cx(styles.email, nameVisible && styles.nameVisible)}>{userId}</span>
        </div>
        <div className={styles.details}>
          <div className={styles.roleInfo}>{getRoleInfo()}</div>
        </div>
        <div className={cx(styles.actions, isEditing && styles.editing)}>{getRowActions()}</div>
      </div>

      {confirmDeleteDialogVisible && (
        <CloseableDialog
          id='remove-user-confirm-dialog'
          title={t`Remove Member`}
          onHide={() => setConfirmDeleteDialogVisible(false)}
          footerActions={
            <>
              {isPersisting ? (
                <Box className={styles.progressContainer}>
                  <CircularProgress size={ACTION_ICON_SIZE} />
                </Box>
              ) : (
                <Button
                  variant='contained'
                  disabled={isPersisting}
                  color='error'
                  onClick={_deleteMember}
                >{t`Remove Member`}</Button>
              )}
            </>
          }
        >
          <DialogContentText>{t`Are you sure you want to remove ${userId}?`}</DialogContentText>
        </CloseableDialog>
      )}
    </div>
  );
}

MemberItem.propTypes = {
  member: PropTypes.shape({
    userId: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  updateMember: PropTypes.func.isRequired,
  deleteMember: PropTypes.func.isRequired,
};

export default MemberItem;
