import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import _ from 'lodash';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import styles from './CourseNameSection.module.scss';

const MAX_LENGTH = 200;

function CourseNameSection(props) {
  const { courseName, onNameChanged } = props;
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setIsValid(!_.isEmpty(_.trim(courseName)));
  }, [courseName]);

  return (
    <div className={styles.content}>
      <h3>{t`Course Name`}</h3>
      <div className={styles.nameContent}>
        <FormControl className={styles.nameForm} fullWidth>
          <TextField
            id='course-name-field'
            name='course-name'
            error={!isValid}
            className={styles.nameInput}
            helperText={!isValid ? t`Course name can't be empty` : null}
            maxLength={MAX_LENGTH}
            value={courseName}
            onChange={(e) => {
              onNameChanged(e.target.value);
            }}
          />
        </FormControl>
      </div>
    </div>
  );
}

CourseNameSection.propTypes = {
  courseName: PropTypes.string,
  onNameChanged: PropTypes.any.isRequired,
};

CourseNameSection.defaultProps = {
  courseName: '',
};

export default CourseNameSection;
