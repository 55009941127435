import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import _ from 'lodash';

// import MenuItem from '@mui/material/MenuItem';
// import Pagination from '@mui/material/Pagination';
// import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';

import { COURSE } from 'common/dattrs';
import CourseListItem from './CourseListItem';
import styles from './CourseList.module.scss';

// const PAGES = [2, 3, 4, 5, 10];
// const PAGES = [10, 25, 50, 100];

function CourseList(props) {
  const { canAdminister, courses } = props;
  const mediumWidth = useMediaQuery('(min-width:600px)');
  const smallWidth = useMediaQuery('(min-width:480px)');

  return (
    <div className={styles.container}>
      <div {...COURSE.list.columns} className={styles.columnLabelContainer}>
        {smallWidth && <span className={styles.nameCol}>{t`Name`}</span>}
        {mediumWidth && canAdminister && <span className={styles.enrollmentCol}>{t`Auto Enrollment`}</span>}
        {smallWidth && <span className={styles.roleCol}>{t`Role`}</span>}
        <span className={styles.actionsCol}></span>
      </div>
      <div className={styles.courseListContainer}>
        <div {...COURSE.list.items} role='list' className={styles.courseList}>
          {_.map(courses, (course) => {
            return (
              <CourseListItem
                key={`course_${course.id}`}
                className={styles.listItem}
                canAdminister={canAdminister}
                course={course}
              />
            );
          })}
        </div>
        {/* <div className={styles.footer}>
          <div className={styles.leftActions}>
            <span id='demo-simple-select-label'>{t`Rows per page:`}</span>
            <Select className={styles.rowSelect} variant={'standard'} labelId='demo-simple-select-label' value={10} onChange={() => {}}>
              {PAGES.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className={styles.rightActions}>
            <Pagination disabled count={10} />
          </div>
        </div> */}
      </div>
    </div>
  );
}

CourseList.propTypes = {
  canAdminister: PropTypes.bool,
  courses: PropTypes.array,
};

CourseList.defaultProps = {
  canAdminister: false,
  courses: [],
};

export default CourseList;
