import {
  ADD_COURSE,
  ADD_COURSE_MEMBER,
  DELETE_COURSE_MEMBER,
  SET_COURSES,
  SET_ACTIVE_COURSE_MEMBERS,
  SET_ACTIVE_COURSE,
  UPDATE_COURSE_SETTINGS,
  UPDATE_COURSE_MEMBER,
} from './course.types';

export function setCourses(payload) {
  return {
    type: SET_COURSES,
    payload,
  };
}

export function setActiveCourse(payload) {
  return {
    type: SET_ACTIVE_COURSE,
    payload,
  };
}

export function setActiveCourseMembers(id, payload) {
  return {
    id,
    type: SET_ACTIVE_COURSE_MEMBERS,
    payload,
  };
}

export function updateCourseSettings(payload) {
  return {
    type: UPDATE_COURSE_SETTINGS,
    payload,
  };
}

export function addCourse(payload) {
  return {
    type: ADD_COURSE,
    payload,
  };
}

export function addCourseMember(payload) {
  return {
    type: ADD_COURSE_MEMBER,
    payload,
  };
}

export function updateCourseMember(payload) {
  return {
    type: UPDATE_COURSE_MEMBER,
    payload,
  };
}

export function deleteCourseMember(payload) {
  return {
    type: DELETE_COURSE_MEMBER,
    payload,
  };
}