import React from 'react';
import PropTypes from 'prop-types';

import styles from './Section.module.scss';

function Section(props) {
  const { children, headerActions, title } = props;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.headerActions}>{headerActions}</div>
      </div>
      <div className={styles.contents}>{children}</div>
    </div>
  );
}

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  headerActions: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  title: PropTypes.string,
};

Section.defaultProps = {
  title: '',
};

export default Section;
