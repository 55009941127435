import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingButton(props) {
  const { children, loading, variant, ...rest } = props;
  return (
    <Button variant={variant} {...rest}>
      {loading ? <CircularProgress size={24} /> : children}
    </Button>
  );
}

LoadingButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  loading: PropTypes.bool,
  variant: PropTypes.string,
};

LoadingButton.defaultProps = {
  loading: false,
  variant: 'outlined',
};

export default LoadingButton;
