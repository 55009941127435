import store from 'redux/store';
import _ from 'lodash';

import { API, graphqlOperation } from 'aws-amplify';
import { getOrgUsers, getInstitutions, getInstitutionInfo } from 'graphql/queries';
import { createOrg } from 'graphql/mutations';
import logger from 'common/utils/logger';

import {
  addInstitution,
  setOrgMembers,
  setCurrentInstitution,
  setInstitutions,
  setInstitutionsLoading,
} from 'redux/institution/institution.actions.js';

const LOGGER_PREFIX = 'InstitutionService';

class InstitutionService {
  fetchInstitutions = async () => {
    try {
      store.dispatch(setInstitutionsLoading(true));
      const orgSummary = await API.graphql(graphqlOperation(getInstitutions));
      if (orgSummary == null) {
        logger.error(`${LOGGER_PREFIX}::fetchInstitutions Failed to fetch orgSummary data for user - unknown cause `);
        return;
      }

      const institutions = _.get(orgSummary, 'data.getOrgs.orgs', []);
      const sorted = _.orderBy(institutions, ['name'], ['asc']);
      logger.debug(`${LOGGER_PREFIX}::fetchInstitutions result`, sorted);

      store.dispatch(setInstitutions(sorted));
      store.dispatch(setInstitutionsLoading(false));
      return institutions;
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchInstitutions`, err);
    }
  };

  fetchInstitutionsAndSetCurrent = async () => {
    try {
      store.dispatch(setInstitutionsLoading(true));
      const institutions = await this.fetchInstitutions();
      logger.debug(`${LOGGER_PREFIX}::fetchInstitutionsAndSetCurrent result`, institutions);
      logger.debug(`${LOGGER_PREFIX}::fetchInstitutionsAndSetCurrent setting default institution`, institutions[0]);
      store.dispatch(setCurrentInstitution(institutions[0]));
      store.dispatch(setInstitutionsLoading(false));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchInstitutionsAndSetCurrent`, err);
    }
  };

  createInstitution = async (name) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::createInstitution config`, { name });
      const result = await API.graphql(graphqlOperation(createOrg, { input: { name } }));
      logger.debug(`${LOGGER_PREFIX}::createInstitution result`, result);
      store.dispatch(addInstitution(_.get(result, 'data.createOrg', {})));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::createInstitution`, err);
    }
  };

  setCurrentInstitution = async (institutionId) => {
    let result;
    try {
      if (institutionId) {
        logger.debug(`${LOGGER_PREFIX}::setCurrentInstitution config`, { id: institutionId });
        result = await API.graphql(graphqlOperation(getInstitutionInfo, { id: institutionId }));
        logger.debug(`${LOGGER_PREFIX}::setCurrentInstitution result`, result);
      }
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::setCurrentInstitution`, err);
    }

    const institution = _.get(result, 'data.getOrg', {});
    store.dispatch(setCurrentInstitution(institution));
    return institution;
  };

  loadOrgMembers = async (orgId) => {
    let result;
    try {
      logger.debug(`${LOGGER_PREFIX}::loadOrgMembers config`, { id: orgId });
      result = await API.graphql(graphqlOperation(getOrgUsers, { id: orgId }));
      logger.debug(`${LOGGER_PREFIX}::loadOrgMembers result`, result);
      store.dispatch(setOrgMembers(orgId, result.data.getOrgUsers.users));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::loadOrgMembers`, err);
    }

    return _.get(result, 'data.getOrgUsers.users', []);
  };
}

export default new InstitutionService();
