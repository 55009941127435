import { API, graphqlOperation } from 'aws-amplify';
import store from 'redux/store';
import _ from 'lodash';
import logger from 'common/utils/logger';

import { getActivities, getActivity } from 'graphql/queries';
import { createActivity as createActivityMutation, updateActivity as updateActivityMutation } from 'graphql/mutations';
import { addActivity, setActivities, updateActivity } from 'redux/activity/activity.actions.js';

const LOGGER_PREFIX = 'ActivityService';
class ActivityService {
  fetchActivities = async (courseId) => {
    try {
      const activityData = await API.graphql(graphqlOperation(getActivities, { courseId }));
      const activities = _.get(activityData, 'data.getActivities.activities');
      const sorted = _.orderBy(activities, ['name'], ['asc']);
      logger.debug(`${LOGGER_PREFIX}::fetchActivities`, sorted);
      store.dispatch(setActivities(sorted));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchActivities`, err);
      throw err;
    }
  };

  fetchActivity = async (id) => {
    try {
      const activityData = await API.graphql(graphqlOperation(getActivity, { id }));
      const activity = _.get(activityData, 'data.getActivity');
      logger.debug(`${LOGGER_PREFIX}::fetchActivity`, activity);
      return activity;
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchActivity`, err);
      throw err;
    }
  };

  createActivity = async ({ courseId, resourceLinkId, name, type }) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::createActivity config`, { courseId, resourceLinkId, name, type });
      const result = await API.graphql(graphqlOperation(createActivityMutation, { input: { courseId, resourceLinkId, name, type } }));
      const activity = _.get(result, 'data.createActivity');
      logger.debug(`${LOGGER_PREFIX}::createActivity result`, activity);
      store.dispatch(addActivity(activity));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::createActivity`, err);
    }
  };

  updateActivity = async ({ id, name }) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::updateActivity config`, { id, name });
      const result = await API.graphql(graphqlOperation(updateActivityMutation, { input: { id, name } }));
      const activity = _.get(result, 'data.updateActivity');
      logger.debug(`${LOGGER_PREFIX}::updateActivity result`, activity);
      store.dispatch(updateActivity(activity));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::updateActivity`, err);
    }
  };
}

export default new ActivityService();
