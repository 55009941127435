import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import logger from 'common/utils/logger';

import Button from '@mui/material/Button';

import { COURSE } from 'common/dattrs';
import CourseList from 'components/course/CourseList';
import CourseAddDialog from 'components/course/CourseAddDialog';
import CourseService from 'service/course';
import EmptyListMessage from 'components/EmptyListMessage';
import Link from 'components/Link';
import Loader from 'components/common/loader/Loader';

import { getCourses } from 'redux/course/course.selectors';
import { getCurrentInstitution } from 'redux/institution/institution.selectors';

import InstitutionContentPage from './InstitutionContentPage';
import styles from './Courses.module.scss';

function AdminCourses(props) {
  const courses = useSelector(getCourses);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const institution = useSelector(getCurrentInstitution);

  async function onCreateCourse(name) {
    try {
      setCreateDialogVisible(false);
      setLoaderVisible(true);
      // TODO we are only allowing creating for the first org that we find
      await CourseService.createCourse(name, institution.id);
      setLoaderVisible(false);
    } catch (err) {
      console.error(`Error creating course '${name}':`, err);
      // showAlert('Create Failure', errorToString(err));
    }
  }

  function courseListEmpty() {
    return !loadingCourses && _.isEmpty(courses);
  }

  function getCourseComponent() {
    if (loadingCourses) {
      return null;
    }

    if (courseListEmpty()) {
      return (
        <EmptyListMessage
          title={t`Start by creating a course`}
          description={t`You don't currently have any courses. Once you have a course, you can enroll instructors and learners in the course, and you can create assignments for those learners to take.`}
          action={
            <Button
              {...COURSE.create}
              className={styles.createButton}
              variant='contained'
              onClick={() => {
                setCreateDialogVisible(true);
              }}
            >{t`Create Course`}</Button>
          }
        />
      );
    }

    return <CourseList canAdminister={true} courses={courses} />;
  }

  function getPageActions() {
    if (loadingCourses || _.isEmpty(courses)) {
      return null;
    }

    return (
      <div className={styles.pageActions}>
        <Button
          {...COURSE.create}
          variant='contained'
          onClick={() => {
            setCreateDialogVisible(true);
          }}
        >{t`Create Course`}</Button>
      </div>
    );
  }

  useEffect(() => {
    async function fetchData() {
      try {
        await CourseService.fetchCourses(institution.id);
      } catch (e) {
        logger.error('Error fetching courses', e);
      }
      setLoadingCourses(false);
    }

    if (institution) {
      fetchData();
    }
  }, [institution]);

  function getPageDescription() {
    if (_.isEmpty(courses)) {
      return null;
    }

    const courseCount = courses.length;
    return (
      <div>
        <span {...COURSE.count} className={styles.courseCount}>
          {courseCount}
        </span>
        <span>{courseCount === 1 ? t`course` : t`courses`}</span>
      </div>
    );
  }

  function getBreadcrumbs() {
    if (!institution) {
      return [];
    }

    return [
      <Link key={'institutions'} to={'/institutions'}>
        {t`Institutions`}
      </Link>,
      <span key={'institution'}>{institution.name}</span>,
    ];
  }

  return (
    <InstitutionContentPage title={t`Courses`} breadcrumbs={getBreadcrumbs()} description={getPageDescription()} actions={getPageActions()}>
      <Loader visible={loaderVisible || loadingCourses} />
      {getCourseComponent()}
      {createDialogVisible && <CourseAddDialog onCancel={() => setCreateDialogVisible(false)} onCreate={onCreateCourse} />}
    </InstitutionContentPage>
  );
}

export default AdminCourses;
