const dattrTree = {
  activity: {
    create: null,
    createDialog: {
      activity: null,
      close: null,
      container: null,
    },
    backToActivityList: null,
    list: {
      columns: null,
      items: null,
      item: {
        launch: null,
        menu: null,
        configure: null,
      },
    },
  },
  course: {
    count: null,
    create: null,
    createDialog: {
      input: null,
      create: null,
    },
    deleteDialog: {
      delete: null,
    },
    editDialog: {
      input: null,
      save: null,
    },
    list: {
      columns: null,
      items: null,
      item: {
        launch: null,
        menu: null,
        members: null,
        edit: null,
      },
    },
    membersDialog: {
      role: null,
      new: {
        email: null,
        role: null,
        add: null,
      },
      members: {
        list: null,
        member: null,
      },
    },
  },
  dialog: {
    close: null,
    title: null,
  },
  general: {
    activityInfo: null,
    bongoLink: null,
    emptyList: null,
  },
  institution: {
    emptyContainer: null,
    count: null,
    create: null,
    createDialog: {
      create: null,
      input: null,
    },
    list: {
      columns: null,
      items: null,
      item: {
        launch: null,
      },
    },
    notSetupContainer: null,
  },
  logo: null,
  login: null,
  page: {
    actions: null,
    breadcrumbs: null,
    description: null,
    loader: null,
    title: null,
    titleActions: null,
  },
  user: {
    fullname: null,
    email: null,
    options: null,
    logout: null,
  },
};

export default dattrTree;
