import { ADD_INSTITUTION, SET_CURRENT_INSTITUTION, SET_INSTITUTIONS, SET_ORG_MEMBERS, SET_INSTITUTIONS_LOADING } from './institution.types';

export function setInstitutions(payload) {
  return {
    type: SET_INSTITUTIONS,
    payload,
  };
}

export function setCurrentInstitution(payload) {
  return {
    type: SET_CURRENT_INSTITUTION,
    payload,
  };
}

export function setOrgMembers(id, payload) {
  return {
    id,
    type: SET_ORG_MEMBERS,
    payload,
  };
}

export function setInstitutionsLoading(loading) {
  return {
    type: SET_INSTITUTIONS_LOADING,
    loading,
  };
}

export function addInstitution(payload) {
  return {
    type: ADD_INSTITUTION,
    payload,
  };
}
