import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import InstitutionService from 'service/institution';

import Page from './Page';

function InstitutionContentPage(props) {
  const { actions, actionsClassName, breadcrumbs, children, className, description, title, titleActions } = props;
  const { institutionId } = useParams();

  useEffect(() => {
    if (institutionId) {
      InstitutionService.setCurrentInstitution(institutionId);
    } else {
      InstitutionService.fetchInstitutionsAndSetCurrent();
    }
  }, [institutionId]);

  return (
    <Page
      actions={actions}
      actionsClassName={actionsClassName}
      breadcrumbs={breadcrumbs}
      className={className}
      description={description}
      title={title}
      titleActions={titleActions}
    >
      {children}
    </Page>
  );
}

InstitutionContentPage.propTypes = {
  actions: PropTypes.node,
  actionsClassName: PropTypes.string,
  breadcrumbs: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  description: PropTypes.node,
  title: PropTypes.string.isRequired,
  titleActions: PropTypes.node,
};

InstitutionContentPage.defaultProps = {
  breadcrumbs: [],
};

export default InstitutionContentPage;
