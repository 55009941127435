import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { generatePath } from 'react-router';

import ListItemButton from '@mui/material/ListItemButton';

import { INSTITUTION } from 'common/dattrs';
import styles from './InstitutionListItem.module.scss';

function InstitutionListItem(props) {
  const { institution } = props;
  const { name, id } = institution;
  const navigate = useNavigate();

  function routeToCourseList() {
    // need to set the context to be this as the selected course??
    const path = generatePath(`/institutions/:institutionId/courses`, {
      institutionId: id,
    });

    navigate(path);
  }

  return (
    <div role='listitem' className={styles.courseItem}>
      <ListItemButton {...INSTITUTION.list.item.launch.q({ name })} component='a' onClick={routeToCourseList} className={styles.details}>
        <span className={styles.name}>{name}</span>
      </ListItemButton>
    </div>
  );
}

InstitutionListItem.propTypes = {
  institution: PropTypes.object.isRequired,
};

export default memo(InstitutionListItem);
