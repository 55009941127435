import _ from 'lodash';

import { ADD_ACTIVITY, UPDATE_ACTIVITY, SET_ACTIVITIES } from './activity.types';

const INITIAL_STATE = {
  activities: [],
  sortColumn: 'name',
  sortDirection: 'asc',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVITIES:
      return { ...state, activities: _.orderBy(action.payload, ['name'], ['asc']) };
    case ADD_ACTIVITY: {
      const activities = [...state.activities, action.payload];
      return { ...state, activities: _.orderBy(activities, ['name'], ['asc']) };
    }
    case UPDATE_ACTIVITY: {
      const activity = action.payload;
      const activities = [...state.activities];
      const activityIndex = _.findIndex(activities, { id: activity.id });
      activities[activityIndex] = activity;
      return { ...state, activities: _.orderBy(activities, ['name'], ['asc']) };
    }
    default:
      return state;
  }
};

export default reducer;
