import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Container from '@mui/material/Container';

import { PAGE } from 'common/dattrs';
import AppBar from 'components/appbar/AppBar';
import BongoLogo from 'components/BongoLogo';

import styles from './Page.module.scss';

function getBreadcrumbs(breadcrumbs) {
  const items = [];
  for (let i = 0; i < breadcrumbs.length; i++) {
    const key = breadcrumbs[i].key || `breadcrumb_item_${i}`;
    items.push(
      <span key={key} className={styles.crumb}>
        {breadcrumbs[i]}
      </span>
    );
    items.push(
      <span key={`breadcrumb_item_${i}`} className={styles.crumbSeparator}>
        /
      </span>
    );
  }
  return items;
}
function Page(props) {
  const { actions, actionsClassName, breadcrumbs, className, description, title, titleActions, children } = props;
  return (
    <div className={cx(styles.page, className)}>
      <AppBar />
      <Container>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <div {...PAGE.breadcrumbs} className={styles.breadcrumbs}>
                {getBreadcrumbs(breadcrumbs)}
              </div>
              <div className={styles.titleContents}>
                <h1 {...PAGE.title} className={styles.title}>
                  {title}
                </h1>
                <div {...PAGE.titleActions} className={cx(styles.titleActions)}>
                  {titleActions}
                </div>
              </div>
            </div>
            <div {...PAGE.actions} className={cx(styles.actionsContainer, actionsClassName)}>
              {actions}
            </div>
          </div>
          <div {...PAGE.description} className={styles.descriptionContainer}>
            {description}
          </div>
          <div className={styles.content}>{children}</div>
          <BongoLogo className={styles.logo} />
        </div>
      </Container>
    </div>
  );
}

Page.propTypes = {
  actions: PropTypes.node,
  actionsClassName: PropTypes.string,
  breadcrumbs: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  description: PropTypes.node,
  title: PropTypes.string.isRequired,
  titleActions: PropTypes.node,
};

Page.defaultProps = {
  breadcrumbs: [],
};

export default Page;
