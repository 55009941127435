import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import logger from 'common/utils/logger';
import { getSessionData, isSuperUser, isTOCSAccepted } from 'redux/user/user.selectors';

import {
  Activity,
  Callback,
  Course,
  Courses,
  Institutions,
  Home,
  Launch,
  Logout,
  Login,
  AdminCourses,
  Terms,
} from './pages';

const LOGGER_PREFIX = 'Router';

function AuthRoute({ children }) {
  const session = useSelector(getSessionData);
  const tocAccepted = useSelector(isTOCSAccepted);
  const location = useLocation();
  const { pathname } = location;

  logger.debug(`${LOGGER_PREFIX}::AuthRoute, location:`, location);

  if (_.isEmpty(session)) {
    logger.debug(`${LOGGER_PREFIX}::AuthRoute, Session is empty, navigating to login.`);
    // saves the current location they were trying to go to when they were redirected. This allows us to send them
    // along to that page after they login,
    return <Navigate to='/login' state={{ from: location }} replace />;
  } else if (!tocAccepted && pathname !== '/terms') {
    return <Navigate to='/terms' state={{ from: location }} replace />;
  } else if (tocAccepted && pathname === '/terms') {
    return <Navigate to='/' />;
  }

  logger.debug(`${LOGGER_PREFIX}::AuthRoute, Valid session, rendering routes`);
  return children;
}

AuthRoute.propTypes = {
  children: PropTypes.node,
};

function AdminRoute({ children }) {
  const _superUser = useSelector(isSuperUser);
  const session = useSelector(getSessionData);
  const tocAccepted = useSelector(isTOCSAccepted);
  const location = useLocation();
  const { pathname } = location;
  const { activityId, courseId } = useParams();

  logger.debug(`${LOGGER_PREFIX}::AdminRoute, location:`, location);

  if (_.isEmpty(session)) {
    logger.debug(`${LOGGER_PREFIX}::AdminRoute, Session is empty, nav to login`);
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  if (!_superUser) {
    // Since they are trying to access a super user route without the correct role, lets see if we can figure out where they are going.
    logger.debug(`${LOGGER_PREFIX}::AdminRoute, Trying to access super user route as non-super user. Received params`, {
      activityId,
      courseId,
    });

    if (activityId && courseId) {
      const path = `/courses/${courseId}/activity/${activityId}`;
      logger.debug(`${LOGGER_PREFIX}::AdminRoute, Routing to activity`, { path });
      return <Navigate to={path} replace />;
    } else if (courseId) {
      const path = `/courses/${courseId}`;
      logger.debug(`${LOGGER_PREFIX}::AdminRoute, Routing to course`, { path });
      return <Navigate to={path} replace />;
    }

    return <Navigate to='/' state={{ from: location }} replace />;
  }

  if (!tocAccepted && pathname !== '/terms') {
    return <Navigate to='/terms' state={{ from: location }} replace />;
  } else if (tocAccepted && pathname === '/terms') {
    return <Navigate to='/' />;
  }

  logger.debug(`${LOGGER_PREFIX}::AdminRoute, Valid session for super user, rendering routes`);
  return children;
}

AdminRoute.propTypes = {
  children: PropTypes.node,
};

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to='home' />} />
        <Route
          path='institutions'
          element={
            <AdminRoute>
              <Institutions />
            </AdminRoute>
          }
        />
        <Route
          path='institutions/:institutionId/courses'
          element={
            <AdminRoute>
              <AdminCourses />
            </AdminRoute>
          }
        />
        <Route
          path='institutions/:institutionId/courses/:courseId'
          element={
            <AdminRoute>
              <Course />
            </AdminRoute>
          }
        />
        <Route
          path='/institutions/:institutionId/courses/:courseId/activity/:activityId'
          element={
            <AdminRoute>
              <Activity />
            </AdminRoute>
          }
        />
        <Route
          path='courses'
          element={
            <AuthRoute>
              <Courses />
            </AuthRoute>
          }
        />
        <Route
          path='courses/:courseId'
          element={
            <AuthRoute>
              <Course />
            </AuthRoute>
          }
        />
        <Route
          path='courses/:courseId/activity/:activityId'
          element={
            <AuthRoute>
              <Activity />
            </AuthRoute>
          }
        />
        <Route
          path='launch'
          element={
            <AuthRoute>
              <Launch />
            </AuthRoute>
          }
        />
        <Route
          path='terms'
          element={
            <AuthRoute>
              <Terms />
            </AuthRoute>
          }
        />
        <Route path='home' element={<Home />} />
        <Route path='login' element={<Login />} />
        <Route path='logout' element={<Logout />} />
        <Route path='callback' element={<Callback />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
