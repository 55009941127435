import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faEllipsisH } from '@fortawesome/pro-duotone-svg-icons/faEllipsisH';
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear';
import { faLinkSimple } from '@fortawesome/pro-solid-svg-icons/faLinkSimple';
import useMediaQuery from '@mui/material/useMediaQuery';

import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import CourseService from 'service/course';
import CourseSettingsDialog from 'components/course/settings/CourseSettingsDialog';
import { COURSE } from 'common/dattrs';
import { copyTextToClipboard } from 'common/utils/domUtils';
import enums from 'graphql/enums';
import { isSuperUser } from 'redux/user/user.selectors';

import EnrollmentStatus from './EnrollmentStatus';
import styles from './CourseListItem.module.scss';

function CourseListItem(props) {
  const { canAdminister, className, course } = props;
  const { name: courseName, role } = course;
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasFocus, setHasFocus] = useState(false);
  const navigate = useNavigate();
  const _isSuperUser = useSelector(isSuperUser);
  const mediumWidth = useMediaQuery('(min-width:600px)');
  const smallWidth = useMediaQuery('(min-width:480px)');
  const [courseSettingsVisible, setCourseSettingsVisible] = useState(false);
  const [generatingLink, setGeneratingLink] = useState(false);
  const [snacksOpen, setSnacksOpen] = useState(false);
  const [downloadingSCORM, setDownloadingSCORM] = useState(false);
  const [downloadSnacksOpen, setDownloadSnacksOpen] = useState(false);
  const canAdministerAnyCourse = canAdminister;
  const canAdministerCourse = _isSuperUser || role === enums.roles.INSTRUCTOR;
  const usersRole = getRoleLabel(role);

  function handleMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  async function onCourseLinkCopy() {
    setGeneratingLink(true);
    const result = await CourseService.generateLink({ courseId: course.id });
    copyTextToClipboard(result);
    setSnacksOpen(true);
    setGeneratingLink(false);
    handleMenuClose();
  }

  async function onDownloadSCORM() {
    setDownloadingSCORM(true);
    const result = await CourseService.generateLink({ courseId: course.id });
    const scorm = await CourseService.downloadSCORMPackage({ courseId: course.id, url: result });
    window.open(scorm, '_self');
    setDownloadSnacksOpen(true);
    setDownloadingSCORM(false);
    handleMenuClose();
  }

  function routeToAssignments() {
    if (_isSuperUser) {
      const path = generatePath(`:courseId`, {
        courseId: course.id,
      });
      navigate(path);
    } else {
      const path = generatePath(`/courses/:courseId`, {
        courseId: course.id,
      });
      navigate(path);
    }
  }

  function getRoleLabel(role) {
    if (_isSuperUser) {
      return t`Super User`;
    }

    switch (role) {
      case enums.roles.INSTRUCTOR:
        return t`Instructor`;
      case enums.roles.LEARNER:
        return t`Learner`;
      default:
        return 'Unknown role';
    }
  }

  function getActionsMenu() {
    if (!canAdministerCourse) {
      return;
    }

    return (
      <>
        <Button
          {...COURSE.list.item.menu}
          aria-label={t`${courseName} options`}
          className={styles.menuButton}
          onClick={handleMenuOpen}
        >
          <BongoFontAwesomeIcon icon={faEllipsisH} size={24} />
        </Button>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            {...COURSE.list.item.copyLink}
            sx={{ padding: 1.5 }}
            disabled={generatingLink}
            onClick={() => {
              onCourseLinkCopy();
            }}
          >
            <ListItemIcon>
              {generatingLink ? <CircularProgress size={24} /> : <BongoFontAwesomeIcon icon={faLinkSimple} size={21} />}
            </ListItemIcon>
            <ListItemText>{t`Copy Link`}</ListItemText>
          </MenuItem>
          <MenuItem sx={{ padding: 1.5 }} disabled={downloadingSCORM} onClick={onDownloadSCORM}>
            <ListItemIcon>
              {downloadingSCORM ? <CircularProgress size={24} /> : <BongoFontAwesomeIcon icon={faDownload} size={21} />}
            </ListItemIcon>
            <ListItemText>{t`Download SCORM package`}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            {...COURSE.list.item.settings}
            sx={{ padding: 1.5 }}
            onClick={() => {
              setCourseSettingsVisible(true);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <BongoFontAwesomeIcon icon={faGear} size={24} />
            </ListItemIcon>
            <ListItemText>{t`Settings`}</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <div role='listitem' className={cx(styles.courseItem, className)}>
      <ListItemButton
        {...COURSE.list.item.launch.q({ id: course.id, name: courseName, role })}
        component='a'
        onClick={routeToAssignments}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
        className={styles.details}
      >
        <div className={styles.nameContainer}>
          <span className={styles.name}>{courseName}</span>
        </div>

        {mediumWidth && canAdministerAnyCourse && (
          <div className={styles.enrollment}>
            {canAdministerCourse && (
              <EnrollmentStatus autoEnrollment={_.get(course, 'autoEnrollment')} showLabel={false} showTooltip={hasFocus} />
            )}
          </div>
        )}

        {smallWidth && (
          <span aria-label={',' + t`Role: ${usersRole}`} className={styles.role}>
            {usersRole}
          </span>
        )}
      </ListItemButton>
      <div className={styles.actionsContainer}>{getActionsMenu()}</div>

      {courseSettingsVisible && (
        <CourseSettingsDialog
          courseId={course.id}
          onHide={() => {
            setCourseSettingsVisible(false);
          }}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        open={snacksOpen}
        onClose={() => {
          setSnacksOpen(false);
        }}
        message={t`Course link copied`}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        open={downloadSnacksOpen}
        onClose={() => {
          setDownloadSnacksOpen(false);
        }}
        message={t`SCORM package download started`}
      />
    </div>
  );
}

CourseListItem.propTypes = {
  canAdminister: PropTypes.bool,
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

CourseListItem.defaultProps = {
  canAdminister: false,
  className: null,
};

export default memo(CourseListItem);
