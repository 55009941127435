import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import cx from 'classnames';
import _ from 'lodash';

import { faTimer } from '@fortawesome/pro-solid-svg-icons/faTimer';

import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import Tooltip from 'components/common/tooltip/Tooltip';

import styles from './EnrollmentStatus.module.scss';

const STATUS_TYPES = {
  open: 'OPEN',
  closed: 'CLOSED',
};

function EnrollmentStatus(props) {
  const { autoEnrollment, className, showLabel, showTooltip } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const autoEnrollmentEnabled = autoEnrollment && autoEnrollment.enabled;
  const hasEnrollmentDate = _.get(autoEnrollment, 'start') || _.get(autoEnrollment, 'end');
  const { i18n } = useLingui();

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  function getFormattedDate(enrollmentMillis) {
    return i18n.date(new Date(enrollmentMillis), {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }

  function getActiveStatus() {
    if (!autoEnrollmentEnabled) {
      return false;
    }

    const start = _.get(autoEnrollment, 'start');
    const end = _.get(autoEnrollment, 'end');
    const now = new Date().getTime();

    if (start && end) {
      return now >= start && now <= end;
    } else if (start) {
      return now >= start;
    } else if (end) {
      return now <= end;
    }

    return true;
  }

  function getAutoEnrollmentMessage() {
    const start = _.get(autoEnrollment, 'start');
    const end = _.get(autoEnrollment, 'end');
    const now = new Date().getTime();

    let dateStr = '';
    let message =
      autoEnrollment && autoEnrollment.enabled
        ? t`Learners can auto enroll in this course at any time`
        : t`Auto Enrollment is not enabled for this course`;
    if (start && end) {
      const startDate = getFormattedDate(start);
      const endDate = getFormattedDate(end);

      if ((now >= start && now <= end) || now < start) {
        message = t`Learners can auto enroll in this course during the following window:`;
      } else {
        message = t`Auto Enrollment is no longer active. Learners were able to auto enroll during the following window:`;
      }
      dateStr = t`${startDate} - ${endDate}`;
    } else if (start) {
      message = t`Learners can auto enroll in this course starting on:`;
      dateStr = getFormattedDate(start);
    } else if (end) {
      if (now <= end) {
        message = t`Learners can auto enroll in this course until:`;
      } else {
        message = t`Auto Enrollment is no longer active. Learners were able to auto enroll until:`;
      }
      dateStr = getFormattedDate(end);
    }

    return { message, date: dateStr };
  }

  function getAutoEnrollmentTooltip() {
    const { message, date } = getAutoEnrollmentMessage();
    return (
      <>
        <div className={styles.tooltipMessage}>{message}</div>
        <div className={styles.tooltipDate}>{date}</div>
      </>
    );
  }

  const isActive = getActiveStatus();
  const activeStatus = isActive ? t`Active` : t`Inactive`;

  const contents = (
    <div
      aria-label={',' + t`Auto Enrollment ${activeStatus}`}
      className={cx(className, styles.enrollment, isActive && styles.enabled)}
    >
      {showLabel && <span className={styles.enrollmentLabel}>{t`Auto Enrollment:`}</span>}
      <div className={styles.enrollmentItem}>
        <span className={styles.itemLabel}>{activeStatus}</span>
        {hasEnrollmentDate && <BongoFontAwesomeIcon className={styles.calIcon} icon={faTimer} size={16} />}
      </div>
    </div>
  );

  return (
    <Tooltip
      className={styles.tooltipContainer}
      open={tooltipOpen || showTooltip}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      title={getAutoEnrollmentTooltip()}
    >
      {contents}
    </Tooltip>
  );
}

EnrollmentStatus.propTypes = {
  autoEnrollment: PropTypes.shape({ enabled: PropTypes.bool, start: PropTypes.number, end: PropTypes.number }),
  className: PropTypes.string,
  showLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

EnrollmentStatus.defaultProps = {
  className: null,
  showLabel: true,
  showTooltip: false,
};

EnrollmentStatus.STATUS_TYPES = STATUS_TYPES;
export default EnrollmentStatus;
