/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by scripts/generate-amplify-config.js
import { Auth } from 'aws-amplify';

const awsmobile = {
  aws_appsync_region: 'us-west-2',
  aws_appsync_graphqlEndpoint: 'https://direct.bongo.cloud/graphql',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_cSdkWCuS9',
  aws_user_pools_web_client_id: '6iqbu715qlfts3ku05807qbsoc',
  oauth: {
    domain: 'auth.direct.bongo.cloud',
    scope: [ 'email', 'profile', 'openid' ],
    redirectSignIn: 'https://direct.bongo.cloud/callback',
    redirectSignOut: 'https://direct.bongo.cloud',
    responseType: 'token'
  },
  graphql_headers: async () => ({
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
  })
};

export default awsmobile;
