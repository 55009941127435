import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { ACTIVITY } from 'common/dattrs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Snackbar from '@mui/material/Snackbar';
import { faEllipsisH } from '@fortawesome/pro-duotone-svg-icons/faEllipsisH';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faLinkSimple } from '@fortawesome/pro-solid-svg-icons/faLinkSimple';

import ActivitySettingsDialog from 'components/activity/ActivitySettingsDialog';
import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import CourseService from 'service/course';
import { copyTextToClipboard } from 'common/utils/domUtils';
import { isSuperUser } from 'redux/user/user.selectors';
import { getCurrentInstitution } from 'redux/institution/institution.selectors';

import styles from './ActivityListItem.module.scss';

const INDIVIDUAL = 'individual_project';
const GROUP = 'group_project';
const QA = 'q_n_a';
const INTERACTIVE_VIDEO = 'interactive_video';

function ActivityListItem(props) {
  const { activity, canAdminister } = props;
  const { id: activityId, name: assignmentName, resourceLinkId, type } = activity;
  const [anchorEl, setAnchorEl] = useState(null);
  const [bongoDialogVisible, setBongoDialogVisible] = useState(false);
  const _isSuperUser = useSelector(isSuperUser);
  const institution = useSelector(getCurrentInstitution);
  const [generatingLink, setGeneratingLink] = useState(false);
  const [snacksOpen, setSnacksOpen] = useState(false);
  const [downloadingSCORM, setDownloadingSCORM] = useState(false);
  const [downloadSnacksOpen, setDownloadSnacksOpen] = useState(false);
  const navigate = useNavigate();
  const assignmentType = getTypeLabel(type);

  function handleMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function getTypeLabel(type) {
    switch (type) {
      case INDIVIDUAL:
        return t`Individual`;
      case GROUP:
        return t`Group`;
      case QA:
        return t`Question and Answer`;
      case INTERACTIVE_VIDEO:
        return t`Interactive Video`;
      default:
        return 'unknown';
    }
  }

  function routeToAssignment() {
    if (_isSuperUser) {
      const path = generatePath(`/institutions/:institutionId/courses/:courseId/activity/:activityId`, {
        courseId: activity.courseId,
        activityId: activity.id,
        institutionId: institution.id,
      });
      navigate(path);
    } else {
      const path = generatePath(`/courses/:courseId/activity/:activityId`, {
        courseId: activity.courseId,
        activityId: activity.id,
      });
      navigate(path);
    }
  }

  async function onAssignmentLinkCopy() {
    setGeneratingLink(true);
    const result = await CourseService.generateLink({ courseId: activity.courseId, activityId: activity.id });
    copyTextToClipboard(result);
    setSnacksOpen(true);
    setGeneratingLink(false);
    handleMenuClose();
  }

  async function onDownloadSCORM() {
    setDownloadingSCORM(true);
    const result = await CourseService.generateLink({ courseId: activity.courseId, activityId: activity.id });
    const scorm = await CourseService.downloadSCORMPackage({
      courseId: activity.courseId,
      activityId: activity.id,
      url: result,
    });
    window.open(scorm, '_self');
    setDownloadSnacksOpen(true);
    setDownloadingSCORM(false);
    handleMenuClose();
  }

  return (
    <div role='listitem' className={cx(props.className, styles.item)}>
      <ListItemButton
        {...ACTIVITY.list.item.launch.q({ id: activity.id, type, name: assignmentName })}
        component='a'
        onClick={routeToAssignment}
        className={styles.details}
      >
        <span className={styles.name}>{assignmentName}</span>
        <span aria-label={',' + t`Assignment type` + ',' + assignmentType} className={styles.role}>
          {assignmentType}
        </span>
      </ListItemButton>
      {canAdminister && (
        <div className={styles.actionsContainer}>
          <Button
            {...ACTIVITY.list.item.menu}
            aria-label={t`${assignmentName} options`}
            className={styles.menuButton}
            onClick={handleMenuOpen}
          >
            <BongoFontAwesomeIcon icon={faEllipsisH} size={24} />
          </Button>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              {...ACTIVITY.list.item.copyLink}
              sx={{ padding: 1.5 }}
              disabled={generatingLink}
              onClick={() => {
                onAssignmentLinkCopy();
              }}
            >
              <ListItemIcon>
                {generatingLink ? (
                  <CircularProgress size={24} />
                ) : (
                  <BongoFontAwesomeIcon icon={faLinkSimple} size={21} />
                )}
              </ListItemIcon>
              <ListItemText>{t`Copy Link`}</ListItemText>
            </MenuItem>
            <MenuItem sx={{ padding: 1.5 }} disabled={downloadingSCORM} onClick={onDownloadSCORM}>
              <ListItemIcon>
                {downloadingSCORM ? (
                  <CircularProgress size={24} />
                ) : (
                  <BongoFontAwesomeIcon icon={faDownload} size={21} />
                )}
              </ListItemIcon>
              <ListItemText>{t`Download SCORM package`}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              {...ACTIVITY.list.item.configure}
              sx={{ padding: 1.5 }}
              onClick={() => {
                setBongoDialogVisible(true);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <BongoFontAwesomeIcon icon={faCog} size={21} />
              </ListItemIcon>
              <ListItemText>{t`Settings`}</ListItemText>
            </MenuItem>
          </Menu>

          {bongoDialogVisible && (
            <ActivitySettingsDialog
              activityId={activityId}
              resourceLinkId={resourceLinkId}
              onHide={() => {
                setBongoDialogVisible(false);
              }}
            />
          )}

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={2000}
            open={snacksOpen}
            onClose={() => {
              setSnacksOpen(false);
            }}
            message={t`Assignment link copied`}
          />

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={2000}
            open={downloadSnacksOpen}
            onClose={() => {
              setDownloadSnacksOpen(false);
            }}
            message={t`SCORM package download started`}
          />
        </div>
      )}
    </div>
  );
}

ActivityListItem.propTypes = {
  activity: PropTypes.object.isRequired,
  canAdminister: PropTypes.bool,
  className: PropTypes.string.isRequired,
};

ActivityListItem.defaultProps = {
  canAdminister: false,
};

export default memo(ActivityListItem);
