import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import _ from 'lodash';

import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';

import { INSTITUTION } from 'common/dattrs';
import CloseableDialog from 'components/common/dialog/CloseableDialog';
import InstitutionService from 'service/institution';
import Loader from 'components/common/loader/Loader';

const MAX_NAME_LENGTH = 200;

function InstitutionCreateDialog(props) {
  const { onHide } = props;
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const buttonDisabled = _.isEmpty(_.trim(name));

  async function createInstitution() {
    setLoading(true);
    await InstitutionService.createInstitution(_.trim(name));
    setLoading(false);
    onHide();
  }

  return (
    <CloseableDialog
      id='create-institution-dialog'
      onHide={onHide}
      title={t`Create Institution`}
      footerActions={
        <Button {...INSTITUTION.createDialog.create} variant='contained' disabled={buttonDisabled} onClick={createInstitution}>
          {t`Create`}
        </Button>
      }
    >
      {loading && <Loader />}
      <DialogContentText>{t`Enter the name of the institution you want to create.`}</DialogContentText>
      <TextField
        id='create-institution-name-field'
        {...INSTITUTION.createDialog.input}
        name='institution-name'
        autoFocus
        fullWidth
        required
        onChange={(event) => setName(event.currentTarget.value)}
        maxLength={MAX_NAME_LENGTH}
        variant='standard'
      />
    </CloseableDialog>
  );
}

InstitutionCreateDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default InstitutionCreateDialog;
