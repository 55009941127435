import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import cx from 'classnames';
import _ from 'lodash';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
// import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import ListItemButton from '@mui/material/ListItemButton';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
// import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons/faBarsFilter';
// import { faArrowUpWideShort } from '@fortawesome/pro-solid-svg-icons/faArrowUpWideShort';

import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import { COURSE } from 'common/dattrs';
import CourseService from 'service/course';
import { getActiveCourseMembers } from 'redux/course/course.selectors';
import logger from 'common/utils/logger';

import MemberItem from './MemberItem';
import styles from './Members.module.scss';

const ACTION_ICON_SIZE = 24;
const MAX_LENGTH = 200;

const PAGE_SIZES = [40];
// const PAGES = [10, 25, 50];
// const PAGES = [2, 3, 4];

function Members(props) {
  const { courseId } = props;
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('learner');
  const [emailValid, setEmailValid] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState(' ');
  const courseMembers = sortMembers(useSelector(getActiveCourseMembers(courseId)));
  const [addingMember, setAddingMember] = useState(false);
  const [isPersisting, setIsPersisting] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize] = useState(PAGE_SIZES[0]);
  const [memberChunks, setMemberChunks] = useState([]);

  const isEmailValid = emailValid && !_.isEmpty(_.trim(userEmail));
  const memberCount = courseMembers ? courseMembers.length : 0;

  function sortMembers(members) {
    return _.orderBy(members, ['givenName', 'familyName', 'userId'], ['asc', 'asc', 'asc']);
  }

  async function onAddMember() {
    setIsPersisting(true);
    const targetMember = { courseId, userId: _.trim(userEmail), role: userRole };

    try {
      await CourseService.addCourseMember(targetMember);
    } catch (e) {
      logger.error('Could not add course member', targetMember);
    }

    setUserEmail('');
    setIsPersisting(false);
    setAddingMember(false);
  }

  function onEmailChange(event) {
    const email = event.target.value;
    setUserEmail(email);

    if (!_.isEmpty(email)) {
      // eslint-disable-next-line no-useless-escape
      const valid = /[^\s]+@[^\s]+\.[^\s]+/.test(email);
      setEmailValid(valid);
      setEmailErrorMessage(valid ? ' ' : t`Enter a valid email address`);
    } else {
      setEmailValid(true);
      setEmailErrorMessage(' ');
    }
  }

  function handlePageChange(event, value) {
    setPage(value);
  }

  async function updateMember(member) {
    await CourseService.updateCourseMember({ oper: 'update', ...member });
  }

  async function deleteMember(member) {
    await CourseService.updateCourseMember({ oper: 'delete', ...member });
  }

  useEffect(() => {
    CourseService.loadCourseMembers(courseId);
  }, [courseId]);

  useEffect(() => {
    setMemberChunks(_.chunk(courseMembers, pageSize));
  }, [courseMembers, pageSize]);

  function getAddInputs() {
    return (
      <Paper elevation={0} className={styles.addPaper}>
        <div className={styles.addContainer}>
          <div className={styles.emailContainer}>
            <FormControl fullWidth>
              <TextField
                id='course-members-entry-field'
                {...COURSE.membersDialog.new.email}
                error={!emailValid}
                className={styles.emailInput}
                name='user-email'
                disabled={isPersisting}
                // eslint-disable-next-line no-useless-escape
                inputProps={{ pattern: '[^s]+@[^s]+.[^s]+' }}
                inputRef={(input) => input && input.focus()}
                label={t`User email`}
                maxLength={MAX_LENGTH}
                value={userEmail}
                onChange={onEmailChange}
              />
            </FormControl>
          </div>
          <div className={styles.roleContainer}>
            <FormControl fullWidth>
              <InputLabel id='course-members-role-field-label'>{t`Role`}</InputLabel>
              <Select
                id='course-members-role-field'
                {...COURSE.membersDialog.new.role}
                labelId='course-members-role-field-label'
                name='user-course-role'
                label='Role'
                disabled={isPersisting}
                value={userRole}
                onChange={(event) => setUserRole(event.target.value)}
              >
                <MenuItem
                  {...COURSE.membersDialog.role.q({ role: 'learner' })}
                  value={'learner'}
                >{t`Learner`}</MenuItem>
                <MenuItem
                  {...COURSE.membersDialog.role.q({ role: 'instructor' })}
                  value={'instructor'}
                >{t`Instructor`}</MenuItem>
              </Select>
            </FormControl>

            <div className={styles.actionsContainer}>
              {isPersisting ? (
                <Box className={styles.progressContainer}>
                  <CircularProgress size={ACTION_ICON_SIZE} />
                </Box>
              ) : (
                <IconButton
                  {...COURSE.membersDialog.new.add}
                  disabled={!isEmailValid}
                  className={styles.addEmailButton}
                  onClick={onAddMember}
                >
                  <BongoFontAwesomeIcon icon={faPlus} size={ACTION_ICON_SIZE} />
                </IconButton>
              )}
              <IconButton
                {...COURSE.membersDialog.new.add}
                className={styles.addEmailButton}
                disabled={isPersisting}
                onClick={() => {
                  setAddingMember(false);
                }}
              >
                <BongoFontAwesomeIcon icon={faXmark} size={ACTION_ICON_SIZE} />
              </IconButton>
            </div>
          </div>
        </div>
        <div className={styles.addError}>{emailErrorMessage}</div>
      </Paper>
    );
  }

  function getPaginationComponent() {
    if (_.isEmpty(courseMembers) || courseMembers.length <= PAGE_SIZES[0]) {
      return null;
    }

    return (
      <div className={styles.footer}>
        {/* <div className={styles.leftActions}>
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id='pagination-page-size-label'>{t`Page size`}</InputLabel>
            <Select
              className={styles.rowSelect}
              labelId='pagination-page-size-label'
              value={pageSize}
              autoWidth
              label={t`Page size`}
              onChange={(event) => {
                setPageSize(event.target.value);
              }}
            >
              {PAGE_SIZES.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div> */}
        <div className={styles.rightActions}>
          <Pagination count={Math.ceil(courseMembers.length / pageSize)} page={page} onChange={handlePageChange} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h3>{t`Members (${memberCount})`}</h3>
      <p className={styles.description}></p>
      <div className={styles.sep}></div>

      {/* <div className={styles.listActions}>
        <TextField
          id='input-with-icon-textfield'
          className={styles.filterField}
          placeholder='Filter by name or email'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <BongoFontAwesomeIcon className={styles.addIcon} icon={faBarsFilter} size={21} />
              </InputAdornment>
            ),
          }}
        />

        <div className={styles.sortContainer}>
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel id='pagination-page-size-label'>{t`Sort by`}</InputLabel>
            <Select
              className={styles.rowSelect}
              labelId='pagination-page-size-label'
              value={pageSize}
              autoWidth
              label={t`Sort by`}
              onChange={(event) => {
                setPageSize(event.target.value);
              }}
              MenuProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <BongoFontAwesomeIcon className={styles.addIcon} icon={faBarsFilter} size={21} />
                  </InputAdornment>
                ),
              }}
            >
              {PAGES.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <IconButton
            {...COURSE.membersDialog.new.add}
            className={styles.addEmailButton}
            disabled={isPersisting}
            onClick={() => {
              setAddingMember(false);
            }}
          >
            <BongoFontAwesomeIcon icon={faArrowUpWideShort} size={ACTION_ICON_SIZE} />
          </IconButton>
        </div>
      </div> */}

      <div>
        <ListItemButton
          component='a'
          onClick={() => {
            setAddingMember(true);
          }}
          className={cx(styles.addMemberButton, addingMember && styles.hidden)}
        >
          <BongoFontAwesomeIcon className={styles.addIcon} icon={faUserPlus} size={28} />
          <span className={styles.name}>{t`Add Member`}</span>
        </ListItemButton>

        {addingMember && getAddInputs()}
      </div>

      <div role='list' {...COURSE.membersDialog.members.list} className={styles.memberList}>
        {_.map(memberChunks[page - 1], (member) => {
          return (
            <MemberItem key={member.userId} member={member} deleteMember={deleteMember} updateMember={updateMember} />
          );
        })}
      </div>

      {getPaginationComponent()}
    </div>
  );
}

Members.propTypes = {
  courseId: PropTypes.string.isRequired,
};

export default Members;
