import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Authenticator, View } from '@aws-amplify/ui-react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import _ from 'lodash';

import logo from 'assets/images/Bongo-white.png';
import { getSessionData } from 'redux/user/user.selectors';
import styles from './Login.module.scss';

const components = {
  Header() {
    return (
      <View className={styles.header} textAlign='center'>
        <img className={styles.logo} src={logo} alt='logo' />
      </View>
    );
  },
};

function Login() {
  const location = useLocation();
  const session = useSelector(getSessionData);

  if (!_.isEmpty(session)) {
    return <Navigate to={'/'} />;
  }

  const { from } = location.state || { from: { pathname: '/', search: '' } };
  // Set the original destination in session storage for the 'login with Google'
  // flow where Cognito redirects back to /callback. The <Callback> component
  // uses this value to determine where the user was trying to go.
  window.sessionStorage.setItem('bongo-pre-login-destination', from.pathname + from.search);

  return (
    <Authenticator
      components={components}
      formFields={{
        signUp: {
          given_name: {
            labelHidden: false,
            placeholder: t`First Name`,
            isRequired: true,
            label: t`First Name`,
          },
          family_name: {
            labelHidden: false,
            placeholder: t`Last Name`,
            isRequired: true,
            label: t`Last Name`,
          },
        },
      }}
      loginMechanisms={['email']}
      signUpAttributes={['email', 'given_name', 'family_name']}
      socialProviders={['google']}
    >
      {({ signOut, user }) => <div />}
    </Authenticator>
  );
}

Login.defaultProps = {
  location: {
    state: {
      from: {
        pathname: '/',
      },
    },
  },
};

Login.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
  }),
};

export default Login;
