import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import _ from 'lodash';

import AvatarGroup from '@mui/material/AvatarGroup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Snackbar from '@mui/material/Snackbar';
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faLinkSimple } from '@fortawesome/pro-solid-svg-icons/faLinkSimple';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ACTIVITY } from 'common/dattrs';
import ActivityList from 'components/activity/ActivityList';
import ActivityService from 'service/activity';
import Avatar from 'components/common/avatar/Avatar';
import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import ActivitySettingsDialog from 'components/activity/ActivitySettingsDialog';
import CourseService from 'service/course';
import CourseSettingsDialog from 'components/course/settings/CourseSettingsDialog';
import CreateActivityDialog from 'components/activity/CreateActivityDialog';
import EmptyListMessage from 'components/EmptyListMessage';
import EnrollmentStatus from 'components/course/EnrollmentStatus';
import InlineMessage from 'components/InlineMessage';
import InstitutionService from 'service/institution';
import Loader from 'components/common/loader/Loader';
import LoadingIconButton from 'components/common/button/LoadingIconButton';
import Tooltip from 'components/common/tooltip/Tooltip';
import { isSuperUser } from 'redux/user/user.selectors';
import { getCurrentInstitution } from 'redux/institution/institution.selectors';
import { getActiveCourse, getActiveCourseMembers } from 'redux/course/course.selectors';
import { getActivities } from 'redux/activity/activity.selectors';
import { copyTextToClipboard } from 'common/utils/domUtils';
import enums from 'graphql/enums';

import InstitutionContentPage from './InstitutionContentPage';
import styles from './Course.module.scss';

const AVATAR_MAX = 5;

function Course(props) {
  const { courseId } = useParams();
  const _isSuperUser = useSelector(isSuperUser);
  const institution = useSelector(getCurrentInstitution);
  const activities = useSelector(getActivities);
  let courseMembers = useSelector(getActiveCourseMembers(courseId));

  const [courseSettingsVisible, setCourseSettingsVisible] = useState(false);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [bongoDialogVisible, setBongoDialogVisible] = useState(false);
  const [createActivityType, setCreateActivityType] = useState(null);
  const [canAdminister, setCanAdminister] = useState(false);
  const [generatingLink, setGeneratingLink] = useState(false);
  const [downloadingSCORM, setDownloadingSCORM] = useState(false);
  const [downloadSnacksOpen, setDownloadSnacksOpen] = useState(false);
  const [snacksOpen, setSnacksOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const course = useSelector(getActiveCourse);
  const showActionsInline = useMediaQuery('(min-width:600px)');

  async function fetchData() {
    setLoadingActivities(true);
    if (courseId) {
      await CourseService.fetchCourse(courseId);
      await ActivityService.fetchActivities(courseId);
    }
    setLoadingActivities(false);
  }

  useEffect(() => {
    fetchData();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (course) {
      setCanAdminister(course.role !== enums.roles.LEARNER);
      InstitutionService.setCurrentInstitution(course.orgId);
    }
  }, [course]); //eslint-disable-line

  useEffect(() => {
    if (canAdminister) {
      CourseService.loadCourseMembers(courseId);
    }
  }, [canAdminister, courseId]);

  async function onCourseLinkCopy() {
    setGeneratingLink(true);
    const result = await CourseService.generateLink({ courseId });
    copyTextToClipboard(result);
    setSnacksOpen(true);
    setGeneratingLink(false);
    handleMenuClose();
  }

  async function onDownloadSCORM() {
    setDownloadingSCORM(true);
    const result = await CourseService.generateLink({ courseId });
    const scorm = await CourseService.downloadSCORMPackage({ courseId, url: result });
    window.open(scorm, '_self');
    setDownloadSnacksOpen(true);
    setDownloadingSCORM(false);
    handleMenuClose();
  }

  function handleMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function getCourseComponent() {
    if (loadingActivities) {
      return null;
    }

    if (!course || _.isEmpty(course)) {
      return (
        <InlineMessage
          title={t`Error fetching course`}
          message={t`Something went wrong getting information for this course`}
        />
      );
    }

    if (_.isEmpty(activities)) {
      if (canAdminister) {
        return (
          <EmptyListMessage
            title={t`Start by creating an assignment`}
            description={t`Currently this course is empty. Once an assignment is created, learners can begin work.`}
            action={
              <Button
                {...ACTIVITY.create}
                className={styles.createButton}
                variant='contained'
                onClick={() => {
                  setCreateDialogVisible(true);
                }}
              >{t`Create Assignment`}</Button>
            }
          />
        );
      } else {
        return (
          <EmptyListMessage
            title={t`No assignments yet`}
            description={t`Looks like you don't have any assignments yet for this course. Once some are created, they will show up here.`}
            onCreateActivity={() => {
              setCreateDialogVisible(true);
            }}
          />
        );
      }
    }

    return (
      <div className={styles.courseContents}>
        <div className={styles.activities}>
          <ActivityList activities={activities} canAdminister={canAdminister} />
        </div>
      </div>
    );
  }

  function getBreadcrumbs() {
    // put in a placeholder link in case something goes wrong loading the course
    if (!course || _.isEmpty(course) || !institution) {
      return [<Link key={'courses'} to={'/courses'}>{t`Courses`}</Link>];
    }

    if (_isSuperUser) {
      return [
        <Link key={'institutions'} to={'/institutions'}>{t`Institutions`}</Link>,
        <span key={'org'}>{course.org}</span>,
        <Link key={'courses'} to={`/institutions/${institution.id}/courses`}>{t`Courses`}</Link>,
      ];
    }

    return [<span key={'org'}>{course.org}</span>, <Link key={'courses'} to={'/courses'}>{t`Courses`}</Link>];
  }

  function getActions() {
    if (!canAdminister) {
      return null;
    }

    return (
      <div className={styles.actions}>
        {showActionsInline && (
          <Tooltip title={t`Settings`}>
            <IconButton
              onClick={() => {
                setCourseSettingsVisible(true);
              }}
            >
              <BongoFontAwesomeIcon size={24} icon={faGear} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip aria-label={t`Course options`} title={t`Options`}>
          <IconButton onClick={handleMenuOpen}>
            <BongoFontAwesomeIcon size={24} icon={faEllipsisVertical} />
          </IconButton>
        </Tooltip>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem sx={{ padding: 1.5 }} disabled={generatingLink} onClick={onCourseLinkCopy}>
            <ListItemIcon>
              {generatingLink ? <CircularProgress size={24} /> : <BongoFontAwesomeIcon icon={faLinkSimple} size={21} />}
            </ListItemIcon>
            <ListItemText>{t`Copy Link`}</ListItemText>
          </MenuItem>
          <MenuItem sx={{ padding: 1.5 }} disabled={downloadingSCORM} onClick={onDownloadSCORM}>
            <ListItemIcon>
              {downloadingSCORM ? <CircularProgress size={24} /> : <BongoFontAwesomeIcon icon={faDownload} size={21} />}
            </ListItemIcon>
            <ListItemText>{t`Download SCORM package`}</ListItemText>
          </MenuItem>
          {!showActionsInline && (
            <>
              <Divider />
              <MenuItem
                sx={{ padding: 1.5 }}
                onClick={() => {
                  setCourseSettingsVisible(true);
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <BongoFontAwesomeIcon icon={faGear} size={21} />
                </ListItemIcon>
                <ListItemText>{t`Settings`}</ListItemText>
              </MenuItem>
            </>
          )}
        </Menu>
      </div>
    );
  }

  function getTitleActions() {
    if (!canAdminister || !showActionsInline) {
      return null;
    }

    return (
      <Tooltip title={t`Copy course link`}>
        <div>
          <LoadingIconButton loading={generatingLink} onClick={onCourseLinkCopy}>
            <BongoFontAwesomeIcon size={24} icon={faLinkSimple} />
          </LoadingIconButton>
        </div>
      </Tooltip>
    );
  }

  function getAvatarsSection() {
    if (_.isEmpty(courseMembers)) {
      return (
        <div className={styles.emptyMembers}>
          <Avatar size={26} randomizeColor={false}>
            <BongoFontAwesomeIcon className={styles.calIcon} icon={faUser} size={16} />
          </Avatar>
          <span className={styles.message}>{t`No Members`}</span>
        </div>
      );
    }

    return (
      <div className={styles.avatars}>
        <AvatarGroup
          sx={{ '& .MuiAvatar-root': { width: 26, height: 26, fontSize: '1em' } }}
          max={AVATAR_MAX}
          total={courseMembers.length}
        >
          {courseMembers.map((member) => (
            <Avatar
              key={member.userId}
              size={26}
              email={member.userId}
              firstName={member.givenName}
              lastName={member.familyName}
              tooltipEnabled={true}
            />
          ))}
        </AvatarGroup>
      </div>
    );
  }

  function getDescription() {
    if (!course || !canAdminister) {
      return null;
    }

    return (
      <div className={styles.courseDetails}>
        <EnrollmentStatus autoEnrollment={_.get(course, 'autoEnrollment')} />
        <div className={styles.memberInfo}>
          <span className={styles.sep}></span>
          {getAvatarsSection()}
        </div>
      </div>
    );
  }

  return (
    <InstitutionContentPage
      className={styles.page}
      title={_.get(course, 'name', '')}
      actions={getActions()}
      titleActions={getTitleActions()}
      actionsClassName={styles.actions}
      description={getDescription()}
      breadcrumbs={getBreadcrumbs()}
    >
      <Loader visible={loadingActivities} />
      {createDialogVisible && (
        <CreateActivityDialog
          onCreate={(type) => {
            setCreateDialogVisible(false);
            setCreateActivityType(type);
            setBongoDialogVisible(true);
          }}
          onHide={() => {
            setCreateDialogVisible(false);
          }}
        />
      )}

      {courseSettingsVisible && (
        <CourseSettingsDialog
          courseId={courseId}
          onHide={() => {
            setCourseSettingsVisible(false);
          }}
        />
      )}

      {bongoDialogVisible && (
        <ActivitySettingsDialog
          courseId={courseId}
          type={createActivityType}
          onHide={() => {
            setBongoDialogVisible(false);
            fetchData();
          }}
        />
      )}

      {getCourseComponent()}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        open={snacksOpen}
        onClose={() => {
          setSnacksOpen(false);
        }}
        message={t`Course link copied`}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        open={downloadSnacksOpen}
        onClose={() => {
          setDownloadSnacksOpen(false);
        }}
        message={t`SCORM package download started`}
      />
    </InstitutionContentPage>
  );
}

export default Course;
