import store from 'redux/store';
import _ from 'lodash';
import logger from 'common/utils/logger';

import { API, graphqlOperation } from 'aws-amplify';
import { getCourse, getCourses, getCourseUsers, verifyJwt, downloadSCORMPackage } from 'graphql/queries'; // eslint-disable-line
import {
  createCourse as createCourseMutation,
  updateCourse,
  addCourseMember,
  updateCourseMember,
  deleteCourseMember,
  generateJwt,
  enrollCurrentUserInCourse,
} from 'graphql/mutations';

import {
  addCourse,
  addCourseMember as addCourseMemberAction,
  updateCourseMember as updateCourseMemberAction,
  deleteCourseMember as deleteCourseMemberAction,
  setActiveCourse,
  setCourses,
  setActiveCourseMembers,
  updateCourseSettings,
} from 'redux/course/course.actions.js';

const LOGGER_PREFIX = 'CourseService';

async function fetchMembers(courseId, nextPageToken) {
  logger.debug(`${LOGGER_PREFIX}::fetchMembers config`, { courseId, nextPageToken });
  const result = await API.graphql(graphqlOperation(getCourseUsers, { id: courseId, nextPageToken }));
  const resultPayload = _.get(result, 'data.getCourseUsers');
  logger.debug(`${LOGGER_PREFIX}::fetchMembers result`, resultPayload);

  let members = _.get(resultPayload, 'users');
  if (resultPayload.nextPageToken) {
    members = [...members, ...(await fetchMembers(courseId, resultPayload.nextPageToken))];
  }

  return members;
}

class CourseService {
  fetchCourses = async (orgId) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::fetchCourses config`, { orgId });
      const courseData = await API.graphql(graphqlOperation(getCourses, { orgId }));
      logger.debug(`${LOGGER_PREFIX}::fetchCourses result`, courseData);
      const courses = _.get(courseData, 'data.getCourses.courses');
      store.dispatch(setCourses(courses));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchCourses`, err);
      throw err;
    }
  };

  fetchCourse = async (id) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::fetchCourse config`, { id });
      const result = await API.graphql(graphqlOperation(getCourse, { id }));
      logger.debug(`${LOGGER_PREFIX}::fetchCourse result`, result);
      const courseData = _.get(result, 'data.getCourse', {});
      store.dispatch(setActiveCourse(courseData));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchCourse`, err);
    }
  };

  createCourse = async (name, orgId) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::createCourse config`, { name, orgId });
      const result = await API.graphql(graphqlOperation(createCourseMutation, { input: { name, orgId } }));
      logger.debug(`${LOGGER_PREFIX}::createCourse result`, result);
      const course = _.get(result, 'data.createCourse');
      store.dispatch(addCourse(course));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::createCourse`, err);
    }
  };

  loadCourseMembers = async (courseId) => {
    let members = [];
    try {
      logger.debug(`${LOGGER_PREFIX}::loadCourseMembers config`, { courseId });
      members = await fetchMembers(courseId);
      logger.debug(`${LOGGER_PREFIX}::loadCourseMembers result`, members);
      store.dispatch(setActiveCourseMembers(courseId, members));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::loadCourseMembers`, err);
    }

    return members;
  };

  addCourseMember = async (targetMember) => {
    logger.debug(`${LOGGER_PREFIX}::addCourseMember config`, { targetMember });
    try {
      const result = await API.graphql(graphqlOperation(addCourseMember, { input: targetMember }));
      logger.debug(`${LOGGER_PREFIX}::addCourseMember result`, result);
      const member = _.get(result, 'data.addCourseMember');
      store.dispatch(addCourseMemberAction(member));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::addCourseMember`, err);
    }
  };

  updateCourseMember = async (changeRequest) => {
    logger.debug(`${LOGGER_PREFIX}::updateCourseMember config`, { changeRequest });
    if (changeRequest.oper === 'delete') {
      try {
        const result = await API.graphql(
          graphqlOperation(deleteCourseMember, {
            input: {
              courseId: changeRequest.courseId,
              userId: changeRequest.userId,
              role: changeRequest.role,
            },
          })
        );

        logger.debug(`${LOGGER_PREFIX}::deleteCourseMember result`, result);
        store.dispatch(deleteCourseMemberAction(_.get(result, 'data.deleteCourseMember')));
      } catch (err) {
        logger.error(`${LOGGER_PREFIX}::deleteCourseMember`, err);
      }
    } else {
      try {
        const result = await API.graphql(
          graphqlOperation(updateCourseMember, {
            input: {
              courseId: changeRequest.courseId,
              userId: changeRequest.userId,
              role: changeRequest.role,
            },
          })
        );

        logger.debug(`${LOGGER_PREFIX}::updateCourseMember result`, result);
        store.dispatch(updateCourseMemberAction(_.get(result, 'data.updateCourseMember')));
      } catch (err) {
        logger.error(`${LOGGER_PREFIX}::updateCourseMember`, err);
      }
    }
  };

  updateCourseSettings = async ({ id, name, autoEnrollment }) => {
    const input = { id, name, autoEnrollment };
    try {
      logger.debug(`${LOGGER_PREFIX}::updateCourseSettings`, { input });
      const result = await API.graphql(graphqlOperation(updateCourse, { input }));
      const course = _.get(result, 'data.updateCourse');
      store.dispatch(updateCourseSettings(course));
      return course;
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::updateCourseSettings`, err);
    }
  };

  generateLink = async (payload) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::generateLink config`, { payload });
      const result = await API.graphql(graphqlOperation(generateJwt, { payload: JSON.stringify(payload) }));
      logger.debug(`${LOGGER_PREFIX}::generateLink result`, result);

      const jwt = _.get(result, 'data.generateJwt');
      const enrollmentUrl = _.get(window, 'location.origin') + `/launch?token=` + jwt;
      logger.debug(`${LOGGER_PREFIX}::generateLink enrollmentUrl:`, enrollmentUrl);
      return _.get(window, 'location.origin') + `/launch?token=` + jwt;
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::generateLink`, err);
    }
  };

  getJwtTokenDetails = async (token) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::validateAutoEnrollment config`, { token });
      const result = await API.graphql(graphqlOperation(verifyJwt, { token }));
      logger.debug(`${LOGGER_PREFIX}::validateAutoEnrollment result`, result);
      return JSON.parse(_.get(result, 'data.verifyJwt'));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::validateAutoEnrollment`, err);
    }
  };

  downloadSCORMPackage = async ({ courseId, activityId, url }) => {
    try {
      const config = { courseId, activityId, targetUrl: url };
      logger.debug(`${LOGGER_PREFIX}::downloadSCORMPackage config`, config);
      const result = await API.graphql(graphqlOperation(downloadSCORMPackage, { input: { ...config } }));
      logger.debug(`${LOGGER_PREFIX}::downloadSCORMPackage result`, result);

      const scormUrl = _.get(result, 'data.getScormZip');
      logger.debug(`${LOGGER_PREFIX}::downloadSCORMPackage zipUrl:`, scormUrl);
      return scormUrl;
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::generateLink`, err);
    }
  };

  enrollCurrentUserInCourse = async (courseId) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::enrollCurrentUserInCourse config`, { courseId });
      const result = await API.graphql(graphqlOperation(enrollCurrentUserInCourse, { input: { courseId } }));
      logger.debug(`${LOGGER_PREFIX}::enrollCurrentUserInCourse result`, result);
      return _.get(result, 'data.autoEnrollCaller');
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::enrollCurrentUserInCourse`, err);
    }
  };
}

export default new CourseService();
