import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingIconButton(props) {
  const { children, loading, variant, ...rest } = props;
  return (
    <IconButton variant={variant} {...rest}>
      {loading ? <CircularProgress size={24} /> : children}
    </IconButton>
  );
}

LoadingIconButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  loading: PropTypes.bool,
  variant: PropTypes.string,
};

LoadingIconButton.defaultProps = {
  loading: false,
  variant: 'outlined',
};

export default LoadingIconButton;
